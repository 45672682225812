import { AuthenticatorService } from '@afe/authentication';
import { SsoTicketAuthenticationModule } from '@afe/authentication/sso-ticket';
import { DLDHEncryptionModule } from '@afe/encryption/dldh';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DssLoaderModule } from '@dss/components/loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoaderHelper } from './models/translate/translate-loader-helper';
import { TemporarySsoTicketAuthenticatorService } from './services/sso/temporary-sso-ticket-authenticator.service';
import { dldhEncryptionConfig } from '../config/dldh-encryption.config';
import { authenticationConfig } from '../config/sso-ticket-authentication.config';
import { EncryptionUtil } from './modules/sharedComponents/encryption/encryption';
import { ConnectionService } from './modules/sharedComponents/services/connection.service';
import { SharedModule } from "./modules/sharedComponents/shared.module";

/**
 * Main Module of App
 */
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: TranslateLoaderHelper.createTranslateLoader,
            deps: [HttpClient],
        },
        defaultLanguage: 'pt-BR',
    }),
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    FormsModule,
    DssLoaderModule,
    DLDHEncryptionModule.forRoot(dldhEncryptionConfig),
    SsoTicketAuthenticationModule.forRoot(authenticationConfig),
    SharedModule
],
  providers: [ EncryptionUtil, TemporarySsoTicketAuthenticatorService, ConnectionService,
    { provide: AuthenticatorService, useClass: TemporarySsoTicketAuthenticatorService, deps: [HttpClient] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
